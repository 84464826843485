import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import slugify from 'react-slugify';
import { Policy } from '../../pages/ranking-polis-mieszkaniowych';
import Button from '../button/button';
import CheckSvg from '../../assets/um-yes-check.svg';
import CheckBlankSvg from '../../assets/um-yes-check-blank.svg';
import NotCheckSvg from '../../assets/um-no-check.svg';
import NotCheckBlankSvg from '../../assets/um-no-check-icon.svg';
import { useState } from 'react';
import ArrowSvg from '../../assets/um-down-arrow.svg';
import Tooltip from '../../assets/um-tooltip.svg';
import config from '../../../config.json';

type Props = {
  policy: Policy;
  logo: IGatsbyImageData | undefined;
  index: number;
};

const RankingItem = ({ policy, logo, index }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleShowDetails = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`mt-8 rounded-md border-t-[6px] bg-white shadow-light lg:mt-10 ${
        index === 1 ? 'border-primary' : 'border-text'
      }`}
      key={policy.nazwaUbezpieczyciela}
    >
      <div className="grid grid-flow-row grid-cols-1 px-5 py-2 lg:grid-cols-4 lg:px-11">
        <div className="flex items-center justify-center lg:col-span-3 lg:justify-start">
          <div
            className={`mr-4 flex h-8 w-8 items-center justify-center rounded-full font-secondary text-2xl font-bold text-white ${
              index === 1 ? 'bg-primary' : 'bg-text'
            }`}
          >
            {index}
          </div>
          <h2 className="text-lg font-semibold lg:text-2xl">
            {policy.nazwaUbezpieczyciela}
          </h2>
        </div>
        {logo && (
          <div className="flex justify-center lg:col-start-4">
            <GatsbyImage image={logo} alt="" />
          </div>
        )}
      </div>
      <div className="border-y-[1px] border-line px-5 py-4 lg:px-11">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
          <div className="flex flex-col">
            <span className="text-xl font-semibold">
              {policy.sumaUbezpieczenia}
            </span>
            <span className="text-sm">Min. suma ubezpieczenia</span>
          </div>
          <div className="flex flex-col">
            <span className="text-xl font-semibold">
              {policy.cenaMinimalna}
            </span>
            <span className="text-sm">Cena minimalna</span>
          </div>
          <div className="flex flex-col">
            <span className="text-xl font-semibold">
              {policy.czasTrwaniaPolisy}
            </span>
            <span className="text-sm">Czas trwania polisy</span>
          </div>
          <div className="flex justify-end ">
            <a href={config.CALC_URL + '?referrer=' + encodeURIComponent('https://www.ubezpieczamymieszkanie.pl/ranking-polis-mieszkaniowych/?selected-ic=' + slugify(policy.nazwaUbezpieczyciela))} target="_blank" id={'ranking-button-' + slugify(policy.nazwaUbezpieczyciela)} class="w-full">
              <Button visualType="primary" className="w-full ">
                Sprawdź
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div className="px-5 py-4 lg:px-11">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
          <div className="flex items-center">
            <img
              src={policy.opcjaAllRisk ? CheckSvg : NotCheckSvg}
              alt=""
              className="w-5"
            />
            <span className="ml-2 flex font-secondary text-sm">
              Opcja All Risk
              <img
                src={Tooltip}
                alt=""
                className="ml-2 w-4"
                data-tip="od wszystkich zdarzeń,<br/> nie tylko nazwanych"
                data-multiline={true}
                data-place="bottom"
              />
            </span>
          </div>
          <div className="flex items-center">
            <img
              src={policy.ubezpieczenieMieszkania ? CheckSvg : NotCheckSvg}
              alt=""
              className="w-5"
            />
            <span className="ml-2 font-secondary text-sm">
              Ubezpieczenie mieszkania
            </span>
          </div>
          <div className="flex items-center">
            <img
              src={policy.ubezpieczenieDomu ? CheckSvg : NotCheckSvg}
              alt=""
              className="w-5"
            />
            <span className="ml-2 font-secondary text-sm">
              Ubezpieczenie domu
            </span>
          </div>
          <div className="flex items-center">
            <img
              src={policy.ubezpieczenieDomuWBudowie ? CheckSvg : NotCheckSvg}
              alt=""
              className="w-5"
            />
            <span className="ml-2 font-secondary text-sm">
              Ubezpieczenie domu w budowie
            </span>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="animate-fade border-t-[1px]  border-line py-4 px-5 lg:px-11">
          <span className="mb-1 block text-lg font-semibold">
            W cenie polisy
          </span>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
            <div className="flex items-center">
              <img
                src={
                  policy.wCeniePolisy.ubezpieczeniePiwnicyLubKomorkiLokatorskiej
                    ? CheckSvg
                    : NotCheckSvg
                }
                alt=""
                className="w-4"
              />
              <span className="ml-2 font-secondary text-sm">
                ubezpieczenie piwnicy lub komórki lokatorskiej
              </span>
            </div>
            <div className="flex items-center">
              <img
                src={
                  policy.wCeniePolisy.ubezpieczenieOdPrzepiec
                    ? CheckSvg
                    : NotCheckSvg
                }
                alt=""
                className="w-4"
              />
              <span className="ml-2 font-secondary text-sm">
                ubezpieczenie od przepięć
              </span>
            </div>
            <div className="flex items-center">
              <img
                src={policy.wCeniePolisy.nnwPsaIKota ? CheckSvg : NotCheckSvg}
                alt=""
                className="w-4"
              />
              <span className="ml-2 font-secondary text-sm">
                NNW psa i kota
              </span>
            </div>
            <div className="flex items-center">
              <img
                src={
                  policy.wCeniePolisy.lokalZastepczy ? CheckSvg : NotCheckSvg
                }
                alt=""
                className="w-4"
              />
              <span className="ml-2 font-secondary text-sm">
                lokal zastępczy
              </span>
            </div>
          </div>
          <span className="mb-1 mt-4 block text-lg font-semibold">
            Możliwe rozszerzenia:
          </span>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
            <div className="flex items-center">
              <img
                src={
                  policy.mozliweRozszerzenia
                    .ubezpieczenieMiejscaParkingowegoLubGarazu
                    ? CheckBlankSvg
                    : NotCheckBlankSvg
                }
                alt=""
                className="w-4"
              />
              <span className="ml-2 font-secondary text-sm">
                ubezpieczenie miejsca parkingowego lub garażu
              </span>
            </div>
            <div className="flex items-center">
              <img
                src={
                  policy.mozliweRozszerzenia.ocWZyciuPrywatnym
                    ? CheckBlankSvg
                    : NotCheckBlankSvg
                }
                alt=""
                className="w-4"
              />
              <span className="ml-2 font-secondary text-sm">
                OC w życiu prywatnym
              </span>
            </div>
            <div className="flex items-center">
              <img
                src={
                  policy.mozliweRozszerzenia.ubezpieczenieOdStluczeniaSzyb
                    ? CheckBlankSvg
                    : NotCheckBlankSvg
                }
                alt=""
                className="w-4"
              />
              <span className="ml-2 font-secondary text-sm">
                ubezpieczenie od stłuczenia szyb
              </span>
            </div>
            <div className="flex items-center">
              <img
                src={
                  policy.mozliweRozszerzenia
                    .ubezpieczenieFotowoltaikiNaDachuDomu
                    ? CheckBlankSvg
                    : NotCheckBlankSvg
                }
                alt=""
                className="w-4"
              />
              <span className="ml-2 font-secondary text-sm">
                ubezpieczenie fotowoltaiki na dachu domu
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        className="flex cursor-pointer justify-center border-t-[1px] border-line py-4 font-secondary text-base"
        onClick={toggleShowDetails}
      >
        <div className="flex items-center">
          {isOpen ? 'Ukryj szczegóły' : 'Pokaż szczegóły'}
          <img
            src={ArrowSvg}
            alt=""
            className={`ml-2 h-[11px] transition-transform ${
              isOpen ? '-rotate-90' : 'rotate-90'
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default RankingItem;
