import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
};

const SectionTag = ({ children, className, tag: Tag = 'div' }: Props) => {
  return (
    <Tag
      className={`w-fit rounded-3xl px-5 py-2 font-secondary text-sm uppercase lg:text-base ${
        className ? className : 'bg-primaryLight text-primary'
      }`}
    >
      {children}
    </Tag>
  );
};

export default SectionTag;
