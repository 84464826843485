import React, { useEffect } from 'react';
import Heading from '../components/heading/heading';
import Layout from '../components/layout/layout';
import SectionTag from '../components/section-tag/section-tag';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import RankingItem from '../components/ranking-item/ranking-item';
import ReactTooltip from 'react-tooltip';
import Seo from '../components/seo/seo';
import LastPosts from '../domain/last-posts/last-posts';
import { useState } from 'react';

export type Policy = {
  cenaMinimalna: string;
  czasTrwaniaPolisy: string;
  fieldGroupName: string;

  nazwaUbezpieczyciela: string;
  opcjaAllRisk: boolean | null;
  sumaUbezpieczenia: string;
  ubezpieczenieDomu: boolean | null;
  ubezpieczenieDomuWBudowie: boolean | null;
  ubezpieczenieMieszkania: boolean | null;
  mozliweRozszerzenia: {
    ocWZyciuPrywatnym: boolean | null;
    ubezpieczenieFotowoltaikiNaDachuDomu: boolean | null;
    ubezpieczenieMiejscaParkingowegoLubGarazu: boolean | null;
    ubezpieczenieOdStluczeniaSzyb: boolean | null;
  };
  wCeniePolisy: {
    lokalZastepczy: boolean | null;
    nnwPsaIKota: boolean | null;
    ubezpieczenieOdPrzepiec: boolean | null;
    ubezpieczeniePiwnicyLubKomorkiLokatorskiej: boolean | null;
  };
  logo: {
    localFile: IGatsbyImageData;
  };
};

type Props = {
  data: {
    allWpPolisa: {
      nodes: { polisa: Policy }[];
    };
    wpPage: {
      seo: {
        metaDesc: string;
        title: string;
        focuskw: string;
      };
    };
  };
};

const Rank = ({
  data: {
    allWpPolisa: { nodes },
    wpPage: { seo },
  },
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const isBrowser = () => typeof window !== 'undefined';

  const paragraphs = [
    `Każdą polisę - także ubezpieczenie mieszkania czy domu - wybieramy
     przede wszystkim z myślą o naszych własnych potrzebach. My staramy
     się nadać tym potrzebom odpowiedni kształt. Sprawdź w jakich
     kategoriach polisy mieszkaniowe są najlepsze, a w których trochę
     słabsze. Dopasuj polisę do swoich oczekiwań.`,
    `Przyjrzyj się także opiniom o tym, jak pracę poszczególnych
     towarzystw ubezpieczeniowych, w których dostępna jest polisa
     mieszkaniowa, oceniają pozostali konsumenci. To ciekawa wskazówka,
     bo czerpiemy ją ze zweryfikowanego źródła opinii.`,
  ];

  function debounce(fn: any, ms: any) {
    let timer: any;
    return (_: any) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  const checkIsMobile = (windowWidth: number) => {
    if (windowWidth >= 1024) {
      console.log(windowWidth);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (typeof window !== 'undefined') checkIsMobile(window.innerWidth);
    }, 200);

    if (isBrowser()) {
      checkIsMobile(window.innerWidth);
      window.addEventListener('resize', debouncedHandleResize);
    }

    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', debouncedHandleResize);
      }
    };
  }, []);

  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        keywords={seo.focuskw}
        pathname="/ranking-polis-mieszkaniowych/"
      />
      <ReactTooltip />
      <div className="bg-blueLight">
        <div className="container mx-auto px-4 py-6 lg:pt-20 lg:pb-0">
          <div className="flex flex-col items-center justify-center text-center">
            <SectionTag tag="h1">RANKING POLIS MIESZKANIOWYCH</SectionTag>
            <Heading tag="h2">
              Znajdź najlepsze ubezpieczenie mieszkania!
            </Heading>
            {isOpen ? (
              paragraphs.map((paragraph, i) => {
                return (
                  <p
                    key={i}
                    className="mt-4 max-w-3xl font-secondary text-lg lg:mt-7"
                  >
                    {paragraph}
                  </p>
                );
              })
            ) : (
              <p className="mt-4 max-w-3xl font-secondary text-lg lg:mt-7">
                {paragraphs[0].slice(0, 130).split(' ').slice(0, -1).join(' ') +
                  '... '}
                <span
                  onClick={() => setIsOpen(true)}
                  className="cursor-pointer text-primary"
                >
                  Pokaż więcej
                </span>
              </p>
            )}
          </div>
          <div className="mt-12">
            {nodes.map((node, i) => {
              const { polisa } = node;
              const image = getImage(polisa.logo.localFile);
              return (
                <RankingItem
                  policy={polisa}
                  logo={image}
                  index={i + 1}
                  key={polisa.nazwaUbezpieczyciela}
                />
              );
            })}
          </div>
        </div>
        <LastPosts />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allWpPolisa(sort: { fields: polisa___kolejnosc, order: ASC }) {
      nodes {
        polisa {
          cenaMinimalna
          czasTrwaniaPolisy
          fieldGroupName
          nazwaUbezpieczyciela
          opcjaAllRisk
          sumaUbezpieczenia
          ubezpieczenieDomu
          ubezpieczenieDomuWBudowie
          ubezpieczenieMieszkania
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 65
                  formats: [AUTO]
                  webpOptions: { quality: 90 }
                  quality: 90
                  placeholder: TRACED_SVG
                )
              }
            }
          }
          kolejnosc
          mozliweRozszerzenia {
            ocWZyciuPrywatnym
            ubezpieczenieFotowoltaikiNaDachuDomu
            ubezpieczenieMiejscaParkingowegoLubGarazu
            ubezpieczenieOdStluczeniaSzyb
          }
          wCeniePolisy {
            lokalZastepczy
            nnwPsaIKota
            ubezpieczenieOdPrzepiec
            ubezpieczeniePiwnicyLubKomorkiLokatorskiej
          }
        }
      }
    }
    wpPage(slug: { eq: "ranking-polis-mieszkaniowych" }) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
  }
`;

export default Rank;
