import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
};

const Heading = ({ children, tag: Tag = 'h2', className, ...rest }: Props) => {
  return (
    <Tag
      className={` mt-4 text-2xl font-semibold lg:mt-7 lg:text-4xl ${className}`}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Heading;
