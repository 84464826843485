import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Post from '../../components/post/post';
import { getImage } from 'gatsby-plugin-image';
import {ReferrerCookie} from "../../components/referrer/referrer";

const LastPosts = () => {
  const referrer = ReferrerCookie();
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { slug: { eq: "blog" } } } }
        }
        sort: { order: DESC, fields: dateGmt }
        limit: 3
      ) {
        edges {
          node {
            dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
            id
            title
            excerpt
            slug
            featuredImage {
              node {
                altText
                mediaItemUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750, height: 400, quality: 90)
                  }
                }
              }
            }
            modifiedGmt(formatString: "DD.MM.YYYY")
            zajawkaBlog {
              zajawka
            }
          }
        }
      }
    }
  `);
  return (
    <div className="mt-10 w-full bg-blueLight">
      <div className="container mx-auto px-4 py-6 lg:py-14">
        <p className="text-center text-2xl font-semibold lg:text-4xl">
          Te artykuły także mogą Ci się spodobać
        </p>
        <div className="mt-10 grid grid-cols-1 gap-14 lg:grid-cols-3">
          {data.allWpPost.edges.map((post: any) => {
            const image = getImage(post.node.featuredImage.node.localFile)!;
            return (
              <Post
                image={image}
                post={post.node}
                type="blog"
                key={post.node.id}
                referrer={ReferrerCookie()}
              ></Post>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LastPosts;
