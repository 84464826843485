import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import CalendarSvg from '../../assets/um-calendar-article.svg';
import { Link } from 'gatsby';
type Post = {
  author: {
    node: {
      description: string;
      name: string;
      seo: {
        title: string;
        metaDesc: string;
      };
      slug: string;
      avatar: {
        url: string;
      };
    };
  };
  slug: string;
  categories: {
    nodes: {
      name: string;
      slug: string;
    };
  };
  dateGmt: Date;
  seo: {
    focuskw: string;
    metaDesc: string;
    title: string;
  };
  content: HTMLCollection;
  title: string;
  uri: string;
  id: string;
  zajawkaBlog: {
    zajawka: string;
  };
  excerpt: string;
};
type Props = {
  post: Post;
  image: IGatsbyImageData;
  type: 'blog' | 'faq';
};

const Post = ({ post, image, type }: Props) => {
  const prefix = {
    blog: 'blog',
    faq: 'faq',
  };

  let excerpt = post.zajawkaBlog.zajawka;

  if (excerpt.length >= 160) {
    excerpt = excerpt.slice(0, 160).split(' ').slice(0, -1).join(' ') + '... ';
  }

  return (
    <Link to={`/${prefix[type]}/${post.slug}/`}>
      <div className="h-full w-full overflow-hidden rounded-md bg-white shadow-light">
        <GatsbyImage image={image} alt="" className="max-h-[300px]" />
        <div className="p-5 lg:p-7">
          <h2 className="text-xl font-semibold lg:min-h-[60px]">
            {post.title}
          </h2>
          <div className="mt-5 flex items-center">
            <img src={CalendarSvg} alt="" />
            <span className="pl-2 font-secondary">{post.dateGmt}</span>
          </div>
          <p className="mt-3 font-secondary">{excerpt}</p>
        </div>
      </div>
    </Link>
  );
};

export default Post;
